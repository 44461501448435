.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 95%;


  #learning {
    margin: 10px auto;

    height: 20px;
    width: 40px;
    border-radius: 0, 50%;
    background-color: rgb(0, 250, 250);
    box-shadow: 15px 15px 15px 4px rgba(0, 0, 0, 0.3);
  }

  .proficient-container {
    display: grid;
    
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;

    #proficient {
      grid-column-start: 1;
      grid-column-end: 4;
      align-self: end;
      // margin: 10px auto;

      // height: 20px;
      // width: 40px;
      // border-radius: 0, 50%;
      // background-color: rgb(0, 50, 250);
      // box-shadow: 15px 15px 15px 4px rgba(0, 0, 0, 0.3);
    }
    // .item-2 {
    //   grid-column: 2/3;
    // }
  }
  .familiar-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;

    #familiar {
      grid-column-start: 1;
      grid-column-end: 4;
      align-self: end;
    }
  }
}

// @function linearBarColor($percentWidth) {
// }

.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  min-width: 135px;
  // margin: 10px;
  padding: 10px;

  .icon-wrapper {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;

    width: 60px;
    min-width: 50px;
    margin: .6rem;
    background-color: rgb(51, 51, 51);
    border-radius: 50%;
    box-shadow: 15px 15px 15px 4px rgba(0, 0, 0, 0.3);
    overflow: hidden;
  }

  img {
    max-height: fit-content;
    max-width: fit-content;
    min-width: 65px;
    min-height: 65px;
    display: inline;
    padding: 1rem;
  }

  p {
    display: inline;
  }

  .bar-container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .bar {
    position: relative;
    width: 90%;
    height: 3vw;
    max-height: 30px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0px 3vw 3vw 0px;
    box-shadow: 15px 15px 15px 4px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(4px);
  }

  .bar-fill {
    position: relative;
    height: 100%;
    border-radius: 0px 3vw 3vw 0px;
    text-align: end;
    padding-left: 10px;
    padding-right: 15px;
    font-size: 0;
    color: white;
    text-shadow: 0.5px 0.5px black;
  }

  @media (min-width: 756px) {

    .icon-wrapper {
      height: 50px;
      width: 50px;
      min-width: 50px;
    }

    img {
      min-width: 65px;
      min-height: 65px;
    }

    // .bar-fill {
    //     font-size: 1.2rem;
    //     font-weight: 700;
    // }

  }

  // .rating-2 {
  //   background-image: linear-gradient(to right, rgb(0, 250, 250), rgb(0, 210, 250));
  //   width: 20%;
  // }
  // .rating-3 {
  //   background: linear-gradient(to right, rgba(0, 250, 250), rgba(0, 190, 250));

  //   width: 30%;
  // }
  // .rating-4 {
  //   background-image: linear-gradient(to right, rgb(0, 250, 250), rgb(0, 170, 250));
  //   width: 40%;
  // }
  // .rating-5 {
  //   background-image: linear-gradient(to right, rgb(0, 250, 250), rgb(0, 150, 250));
  //   width: 50%;
  // }

  // .rating-6 {
  //   /* double the width #, subtract from 250 and update the second color middle number with that value for each of the following backgrounds*/
  //   background-image: linear-gradient(to right, rgb(0, 250, 250), rgb(0, 130, 250));
  //   width: 60%; /* increase with experience */
  // }

  // .rating-7 {
  //   background-image: linear-gradient(to right, rgb(0, 250, 250), rgb(0, 110, 250));
  //   width: 70%;
  // }

  // .rating-8 {
  //   background-image: linear-gradient(to right, rgb(0, 250, 250), rgb(0, 90, 250));
  //   width: 80%;
  // }
}

@media (min-width: 756px) {

  .skills-container{
    width: 75%;
    max-width: 1248px;
  }
}
