.side-drawer {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    height: 100%;
    // background: rgba(245, 245, 245, 0.7);
    background: #23395d;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 300;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;

    a {
        width: 100%;
        height: 100%;
        flex-grow: 1;
    }
}

.side-drawer.open {
    transform: translateX(0);
}

.side-drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.side-drawer li {
    margin: 0.5rem;
}

.side-drawer a:hover {
    color: yellow;
}
.side-drawer a:active {
    color: yellow;
}

.side-drawer a {
    color: yellowgreen;
    text-decoration: none;
}
