.about-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-height: 100vh;
}

.flex-center {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}


@media (min-width: 1600px) {
    .about-container {
        width: 1600px;
        align-self: center;
    }
}
