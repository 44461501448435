@import url("https://fonts.googleapis.com/css2?family=Sedgwick+Ave&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Sedgwick+Ave&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Poiret+One&family=Sedgwick+Ave&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400&family=Poppins&family=Sansita+Swashed&display=swap');

@font-face {
  font-family: 'nikea';
  src: url('./assets/fonts/NIKEA.otf');
}



$title-font-size: 2rem;
$secondary-font: 'Poppins', sans-serif;
// font-family: 'Sansita Swashed', cursive;
// $secondary-font: "Dancing Script", cursive;
$title-font: "Supermercado One", cursive;
$bar-color:#192841;
$bar-fade-color:#23395D;

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.App {
    text-align: center;
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: $title-font-size;
    font-family: "Sedgwick Ave", cursive;
    font-family: $secondary-font;
    color: white;
}

.page-transition-appears {
    opacity: 0;
}
.page-transition-appears-done {
    opacity: 1;
}
.page-transition-enter-done {
    opacity: 1;
}
.page-transition-enter {
    opacity: 0;
}
.page-transition-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}
.page-transition-exit {
    opacity: 1;
}
.page-transition-exit-active {
    opacity: 0;
}

.page-title {
    font-family: $secondary-font;
    font-weight: 700;
    font-size: 3rem;
}

@media (min-width: 756px) {
    .page-title {
        font-weight: 800;
        font-size: 3rem;
    }
}
