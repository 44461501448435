.blured-container {
    color: black;
    width: 90%;
    max-width: 800px;
    min-width: 350px;
    height: fit-content;
    border-radius: 2rem;
    background-color: rgba($color: #ffff, $alpha: 0.3);
    backdrop-filter: blur(5px);
    padding: 15px;
    margin: 10px 10px 10px 10px;
    box-shadow: 15px 15px 15px 4px rgba(0, 0, 0, 0.3);
    font-size: large;

    h2 {
        color: black;
    }
}
