@import "./../../App.scss";
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Poiret+One&family=Sedgwick+Ave&family=Supermercado+One&display=swap");

.card {
    box-sizing: border-box;
    border-radius: 20px;
    min-height: 390px;
    max-width: 400px;
    min-width: 340px;
    transition: 0.5s ease-in-out;
}

.card-header {
    background-image: linear-gradient(
        5deg,
        $bar-color 70%,
        #23395d
    );
    // font-family: "Supermercado One", cursive;
    font-family: $secondary-font;
    font-size: large;
    color: white;
    margin-bottom: -5px;
}

.card-img-top {
    object-fit: contain;
    max-height: 400px;
    padding: 20px 0 20px 0;
    // background-color: black;
    background-color: black;
    border-radius: 0%;
}

.rnd-top-corners {
    border-radius: 20px 20px 0 0 !important;
}

.card-body {
    display: flex;
    align-items: center;
    border-radius: 0 0 20px 20px;
    object-fit: contain;
    margin-top: -9px;
    background-color: $bar-color;
}

.custom-cards {
    width: 90%;
    box-shadow: 15px 15px 15px 4px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    font-size: 1rem;
}

@media (min-width: 1025px) {
    .card:hover {
        transform: scale(1.03);
    }
}
