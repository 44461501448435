@import "../../App.scss";

.toolbar {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    background-image: linear-gradient(
        2deg,
        $bar-color 70%,
        #23395d
    );
    height: 50px;
    z-index: 200;
    box-shadow: 0px 2px 2px 0px rgba($color: #000000, $alpha: 0.2);
}

.toolbar__logo {
    margin: 0 auto;
    color: white;
    font-family: $secondary-font;
    font-size: 1.3rem;
}

.toolbar__logo a {
    color: white;
    text-decoration: none;
    font-size: 1.3rem;
}

.spacer {
    flex: 1;
}
.toolbar__navigation {
    display: flex;
    height: 100%;
    padding: 0 1rem;
    align-items: center;
}

.toolbar_navigation-items a {
    color: white;
    text-decoration: none;
}

.toolbar_navigation-items ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.toolbar_navigation-items li {
    padding: 0 0.5rem;
}

.full-stack {
    display: none;
}

@media screen and (min-width: 768px) {
    .full-stack {
        display: inline-block;
    }
}
