.contact-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-height: 100vh;
}

.social-icon {
    height: 100px;
    width: 100px;
    max-height: 75px;
    max-width: 75px;
    min-width: 75px;
    min-height: 75px;
    margin: 0 5%;
    color: white;
    font-weight: bolder;

    img {
        box-shadow: 15px 15px 15px 4px rgba(0, 0, 0, 0.3);
        border-radius: 7px;
    }

    #github-icon {
        padding: -10px;
        background: radial-gradient(white 58%, black 60%);
        border-radius: 50%;
        // outline: 3px solid black;
        // outline-offset: -3px;
        overflow: hidden;
        box-shadow: 15px 15px 15px 4px rgba(0, 0, 0, 0.3);
    }

    .github-border {
        margin: 10px;
        background-color: white;
        border-radius: 50%;
        // border: 2px solid black;
    }

    #indeed-icon {
        max-height: 70px;
        max-width: 70px;
    }

    #linkedIn-icon {
        object-position: 2px 2px;
    }
}
