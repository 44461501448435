.topbar {
    background-color: teal;
    height: 50px;
}

// Ios work around for fixed background
body:before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    // background: url("../../assets/sandsBackdrop_Jan_Kopriva.jpeg") no-repeat
    //     center center;
    // background: url("../../assets/large-triangles.svg");
    background: url("../../assets/subtle-prism.svg");
    background: url("../../assets/subtle-prism-2.svg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.layer {
    // position: relative;
    // height: 100%;
    // width: 100%;
    // background-color: transparentize($color: #ffff, $amount: 0.5);
}

.fade-in-overlay {
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 50px;
    left: 0px;
    background-color: white;
    opacity: 0;
    animation-name: fade-in;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    z-index: 0;
}

@keyframes fade-in {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
