@import "./../../App.scss";

$small-container-width: 300px;
$small-container-height: 180px;

.main {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .secret-button {
      display: hidden;
      color: transparent;
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;
      z-index: 2;
      font-size: .75rem;
    }
    .secret-button:hover {
      background-color: black;
      color: white
    }
}

.tech {
  background-image: url("../../assets/187161.jpg");
}

.paper {
  background-image: url("../../assets/abstract-paper-mobile.svg");
}



.overlay {
    color: white;
    z-index: 0;
}

.cover {
    position: absolute;

    width: 100%;
    height: 100vh;
    color: black;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);

    transition: 1s;
}

h2,
.subtitle {
    font-size: $title-font-size ;
    color: white;
    font-family: $secondary-font;
    font-weight: 400;
    transition: 2s;
}

.subtitle {
    font-family: "Poiret One", cursive;
    font-size: $title-font-size - 0.5rem;
}

.small-container {
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    width: $small-container-width;
    height: $small-container-height;
    // border: rgba(0, 0, 0, 0) 1px solid;
    border-radius: 8px;
    transition: 1s;
    text-decoration: none;
    padding: 10px;
}

.hover-trigger {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 60%;
    z-index: 10;
}


@media (min-width: 756px) {

    .hover-trigger:hover ~ .cover {
        background-color: rgba(255, 255, 255, 0.7);
    }

    .hover-trigger:hover {
        h2,
        .subtitle {
            display: block;
            color: black;
            // font-size: $title-font-size * 1.1;
        }
        .small-container {
            // border-color: black;
            text-decoration: none;
            padding: 5px;
            transform: scale(125%);
            // width: $small-container-width * 1.6;
            // height: $small-container-height * 1.6;
            // background-color: rgba($color: #fff, $alpha: 0.3);
            // box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        }
    }
}

@media (min-width: 756px) and (max-height: 1000px) {

  // .main {
  //   background-image: url("../../assets/abstract-paper.svg");
  //   // background-image: url("../../assets/187161.jpg");
  // }
}