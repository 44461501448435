.projects-container {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    height: 100%;
    min-height: 100vh;
    align-items: center;
}

.card-container {
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {

  .cards-container {
       display: flex;
       flex-wrap: wrap;
       justify-content: flex-start;
       max-width: calc(416px * 2);
  }
  .card-container {
    max-width: calc(100% / 2);
  }
}

@media (min-width: 1200px) {

  .cards-container {
       display: flex;
       flex-wrap: wrap;
       justify-content: flex-start;
       max-width: calc(416px * 3);
  }
  .card-container {
    display: flex;
    justify-content: center;
    max-width: calc(100% / 3);
  }
}